.ScoreKeeper {
    background: tan;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 0.25rem 1rem;

    &__player {
        margin-bottom: 0.5rem;
        padding: 0.1rem;

        &--playing {
            background: teal;
            color: white;
        }
    }
}