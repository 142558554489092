.Board {
    width: 90vw; // TODO: update later after testing
    max-height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    &__subtitle {
        width: 100%;
        text-align: left;
    }
    &__card {
        flex: 1 0 auto;
        min-width: 15vw;
        height: 30vw;
        margin: 0.5rem 0.5rem 0 0;
    }

    &__winner-announcement {
        position: absolute;

        h2 {
            color: teal;
            font-size: 4rem;
        }
    }

    &__modal {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;

        &--button {

            padding: 2.5rem 5rem;
            border-radius: 0.25rem;
            border: 2px white;
            background-color: darkslategray;
            color: white;
            font-size: 4rem;
            box-shadow: 0 0px 25px 22px #fff
        }
    }
}