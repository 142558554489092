.Card {
    width: 5rem;
    height: 10rem;
    background: transparent;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 15px;
    overflow: hidden;
    transition: all 0.6s;
    perspective: 1000px;

    &__face {
        width: 100%;
        height: 100%;
        background: transparent;
        transition: transform 0.5s;
        transform-style: preserve-3d;

        &--flipped {
            transform: rotateY(180deg);
        }

        &--front, &--back {
            position: absolute;
            width: 100%;
            height: 100%;
            backface-visibility: hidden;
            background-size: 1300% 400%;
        }

        &--back {
            background-position: 0 0;
            transform: rotateY(180deg);
            background-image: url('/card-back.png') !important;
            background-size: 100% 100%;
        }
    }

    &__found {
        pointer-events: none;
        opacity: 0;
    }
}